<template>
  <div data-cy="instruction-page" class="instruction-page">
    <main class="insturct_main">
      <div class="content-block insturction-container">
        <div class="banner">
          <img src="@/assets/img/banner.jpg" alt="" />
        </div>
        <h1 class="center">{{ $t("page.instruction.main.title") }}</h1>
        <p class="subtitle center">
          {{ $t("page.instruction.main.subtitle.partFirst") }}
          <br /><span style="color: #8153f3">{{
            $t("page.instruction.main.subtitle.partSecond")
          }}</span>
          {{ $t("page.instruction.main.subtitle.partThird") }}
        </p>

        <div class="insturction">
          <h2 class="center">{{ $t("page.instruction.title") }}</h2>
          <div class="insturction_info">
            <div class="insturct_step">
              <div class="insturct_step-head flex-s">
                <span>1</span>
                <p>
                  {{ $t("page.instruction.stepFirst.head") }}
                </p>
              </div>
              <div class="insturct_step-body">
                <div class="insturct-action flex-s">
                  <span
                    class="cursor-pointer"
                    :class="getTabClass('copy')"
                    @click="onClickTab('copy')"
                  >
                    {{ $t("page.instruction.stepFirst.actionCopy") }}
                  </span>
                  <span
                    class="cursor-pointer"
                    :class="getTabClass('form')"
                    @click="onClickTab('form')"
                  >
                    {{ $t("page.instruction.stepFirst.actionTab") }}
                  </span>
                </div>

                <div v-if="tab === 'copy'" class="tab-container">
                  <div class="field">
                    <input
                      ref="link"
                      class="input"
                      type="text"
                      name="org"
                      placeholder=" "
                      :value="referralLink"
                      readonly
                    />
                    <span class="placeholder">{{ $t("label.name") }}</span>

                    <span data-event="copied_link" class="copied_link" @click="onClickCopy">
                      <img src="@/assets/img/icon/copy.svg" alt="" />
                    </span>
                  </div>
                  <p>{{ $t("page.instruction.tabs.copy") }}</p>
                </div>

                <div v-else class="tab-container">
                  <p class="mb-6">
                    {{ $t("page.instruction.tabs.form") }}
                  </p>

                  <ReferralForm
                    ref="referralForm"
                    class="pb-3"
                    :user-hash="userHash"
                    :regions="regions"
                    @submitForm="onSubmitForm"
                  />

                  <button class="but" @click="onClickSubmit">{{ $t("button.send") }}</button>
                </div>
              </div>
            </div>

            <div class="insturct_step">
              <div class="insturct_step-head flex-s">
                <span>2</span>
                <p>{{ $t("page.instruction.stepSecond.head") }}</p>
              </div>
              <div class="insturct_step-body">
                <ul class="list">
                  <li>{{ $t("page.instruction.stepSecond.list.itemFirst") }}</li>
                  <li>{{ $t("page.instruction.stepSecond.list.itemSecond") }}</li>
                  <li>{{ $t("page.instruction.stepSecond.list.itemThird") }}</li>
                  <li>{{ $t("page.instruction.stepSecond.list.itemFourth") }}</li>
                </ul>
              </div>
            </div>

            <div class="insturct_step">
              <div class="insturct_step-head flex-s">
                <span>3</span>
                <p>{{ $t("page.instruction.stepThird.head") }}</p>
              </div>
              <div class="insturct_step-body">
                <p>{{ $t("page.instruction.stepThird.description") }}</p>
              </div>
            </div>
          </div>

          <div class="flex-b insturction_footer">
            <p class="headline1">{{ $t("page.instruction.footer.title") }}</p>
            <p class="insturction_footer-info">
              <span @click="onClickCopy">
                {{ $t("page.instruction.footer.description.partFirst") }}
                <img src="@/assets/img/icon/copy1.svg" alt="" />
              </span>
              <br class="visible-xs" />{{ $t("page.instruction.footer.description.partSecond") }}
            </p>
          </div>
        </div>

        <p class="subtitle center">
          {{ $t("page.instruction.main.footerDescription") }}
        </p>
        <div class="but-center" @click="onClickChooseSolution">
          <div class="but-link but but_mod">{{ $t("button.chooseSolution") }}</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { regionsQuery } from "./gql/queries";

import "@/assets/lib/jquery.maskedinput";
import "@/assets/js/common/common";
import "@/assets/css/main.css";

import ReferralForm from "./components/ReferralForm";
import notify from "@/services/notify.service";
import storage from "@/services/storage.service";
import ButtonElement from "@/components/ButtonElement.vue";

const CONTENT_WIDTH = 1126;

export default {
  name: "Instruction",

  components: {
    ButtonElement,
    ReferralForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      regions: [],
      tab: "copy",
    };
  },

  computed: {
    ...mapGetters(["userHash"]),

    referralLink() {
      return `${window.location.origin}/landing?agent=${this.userHash}`;
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  async created() {
    await this.getRegions();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapActions("publicReferral", ["createReferral"]),

    async getRegions() {
      const { regions } = await this.$get(regionsQuery);

      // TODO: fix when will be country switch
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));
      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
    },

    getTabClass(tab) {
      return this.tab === tab ? "active" : "";
    },

    onClickTab(tab) {
      this.tab = tab;
    },

    onClickCopy() {
      try {
        this.$refs.link.select();

        document.execCommand("copy");
        notify.success("copiedSuccessfully");
      } catch (error) {
        notify.error("copyError");
      }
    },

    onClickChooseSolution() {
      this.$router.push({ name: "Advices" });
    },

    onClickSubmit() {
      this.$refs.referralForm.submitForm();
    },

    async onSubmitForm(referralData) {
      const response = await this.createReferral(referralData);

      storage.setObj("notify", { type: "success", message: response.data.message });

      await this.$router.push({ name: "AgentReferrals" });
    },
  },
};
</script>

<style src="@/assets/css/adaptiv.css" scoped />
