<template>
  <div data-cy="ref-form" class="referral-form">
    <InputElement
      v-model="form.firstName"
      data-cy="first-name-input"
      class="component-input"
      :with-error="errors.firstName"
      :error-message="$t('validation.required')"
      :placeholder="$t('placeholder.fullName')"
      @blur="removeErrorClass('firstName')"
      @input="removeErrorClass('firstName')"
    />

    <CleaveElement
      :value="phoneWithPlus"
      :options="cleaveOptions"
      data-cy="phone-input"
      class="component-input"
      :placeholder="$t('label.phone')"
      :with-error="errors.phone"
      :error-message="phoneErrorMsg"
      @input="onChangePhone"
      @blur="removeErrorClass('phone')"
    />

    <MultiselectElement
      :value="checkValueExists(form.region)"
      data-cy="region-select"
      class="component-input-select"
      :options="regions"
      item-label="name"
      :with-error="errors.region"
      :error-message="$t('validation.required')"
      :placeholder="$t('label.region')"
      @select="onSelectRegion"
      @open="removeErrorClass('region')"
    />

    <TextareaElement
      v-model="form.comment"
      data-cy="comment-input"
      class="component-input-textarea"
      :placeholder="$t('label.comment')"
      @blur="removeErrorClass('comment')"
      @input="removeErrorClass('comment')"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import TextareaElement from "@/components/form/TextareaElement";
import CleaveElement from "@/components/form/CleaveElement";

export default {
  name: "ReferralForm",
  components: {
    InputElement,
    MultiselectElement,
    TextareaElement,
    CleaveElement,
  },

  mixins: [apolloMixin],

  props: {
    regions: {
      type: Array,
      default: () => [],
    },

    userHash: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    form: {
      agentHash: "",
      firstName: "",
      phone: "38",
      region: null,
      comment: "",
    },
    errors: {
      firstName: false,
      phone: false,
      region: false,
    },
    cleaveOptions: {
      phone: true,
      phoneRegionCode: "UA",
      delimiter: "-",
    },
    phoneMinLength: 11,
    phoneMaxLength: 13,
    commentMaxLength: 255,
    minPowerKw: 10,
    maxPowerKw: 1000,
  }),

  computed: {
    phoneWithPlus() {
      return `+${this.form.phone}`;
    },

    phoneNumberLength() {
      const phoneRegExp = /\D/g;

      return this.form.phone.replace(phoneRegExp, "").length;
    },

    phoneErrorMsg() {
      return !this.phoneNumberLength
        ? this.$t("validation.required")
        : this.$t("validation.phoneLength");
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  watch: {
    userHash: {
      handler: "onChangeUserHash",
      immediate: true,
    },
  },

  methods: {
    ...mapActions("publicReferral", ["createReferral"]),

    onChangeUserHash() {
      this.form.agentHash = this.userHash;
    },

    onChangePhone(phone) {
      this.form.phone = phone;
      this.removeErrorClass("phone");
    },

    onSelectRegion(region) {
      this.form.region = region;
    },

    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.phone = !this.phoneNumberLength;
      this.errors.phone =
        this.phoneNumberLength < this.phoneMinLength ||
        this.phoneNumberLength > this.phoneMaxLength;
      this.errors.region = !this.form.region;

      return !Object.values(this.errors).some((error) => error);
    },

    submitForm() {
      if (!this.isValidForm()) return;

      const referralData = {
        agentHash: this.form.agentHash,
        name: this.form.firstName,
        phone: this.form.phone,
        regionId: this.form.region.id,
        comment: this.form.comment,
      };

      this.$emit("submitForm", referralData);
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-form:deep {
  max-width: 400px;

  .form-group {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .form-control {
    font-weight: 400;
  }

  .component-input-select {
    .multiselect__placeholder {
      font-weight: 400;
    }
  }

  .component-input-textarea {
    .app-textarea {
      height: 93px;
    }
  }
}
</style>
